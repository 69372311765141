// create slice for for fecth data from firestore with redux and redux thunk

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { firestoreDB } from "../../firebase/firebase.config";
// define  initialState

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

// createAsyncThunk for fetch data from firestore

export const fetchDataFromFirestore = createAsyncThunk(
  "firestore/fetch",
  async (collectionName) => {
    try {
      const collectionRef = collection(firestoreDB, collectionName);
      const snapshot = await getDocs(collectionRef);
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp.toDate().toISOString(),
      }));
      return data;
    } catch (error) {
      throw error;
    }
  }
);

// create slice

const fileStoreSlice = createSlice({
  name: "firestore",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataFromFirestore.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchDataFromFirestore.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        console.log("Received Data:", action.payload);
      })
      .addCase(fetchDataFromFirestore.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// export reducer

export default fileStoreSlice.reducer;
