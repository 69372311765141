// creating about page
import React from "react";
import "./About.styles.scss";
import { useDocumentTitle } from "../../hooks";

const AboutPage = ({ ...props }) => {
  useDocumentTitle("thesyscoder | About", false);
  return (
    <div className="about_page_div">
      <h1>This is about page</h1>
    </div>
  );
};

export default React.memo(AboutPage);
