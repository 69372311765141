import React from "react";
import "./Experience.style.scss";
import { useDocumentTitle } from "../../hooks";

const ExperiencePage = ({ ...props }) => {
  useDocumentTitle("thesyscoder | Experience", false);
  return (
    <div className="experience_page_div">
      <h1>This is experience page</h1>
    </div>
  );
};

export default React.memo(ExperiencePage);
