// useDocumentTitle.js
import { useRef, useEffect } from "react";

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    // Store the current value of defaultTitle.current in a local variable
    const prevDefaultTitle = defaultTitle.current;

    return () => {
      if (!prevailOnUnmount) {
        document.title = prevDefaultTitle;
      }
    };
  }, [prevailOnUnmount]);

  // If you want to set a default title when the component mounts
  useEffect(() => {
    return () => {
      document.title = "thesyscoder | Default Title";
    };
  }, []);

  // Optionally, you can return the current document title if needed
  // return document.title;
}

export default useDocumentTitle;
