// import required modules

import React from "react";
import "./Home.style.scss";
import { useDocumentTitle } from "../../hooks";

// create Home Page

const HomePage = () => {
  useDocumentTitle("thesyscoder | Home", false);
 return(
  <div className="home-main-container">
    <h2>This is home page</h2>
  </div>
 )
  
};

export default React.memo(HomePage);
