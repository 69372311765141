// contact page
import React from "react";
import "./Contact.style.scss";
import { useDocumentTitle } from "../../hooks";

const ContactPage = ({ ...props }) => {
  useDocumentTitle("thesyscoder | Contact", false);
  return (
    <div className="conatct_page_div">
      <h1>This is contact page</h1>
    </div>
  );
};

export default React.memo(ContactPage);
