// required imports
import React, { Fragment } from "react";
import "./App.scss";
import { Navbar } from "./components/molecules";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  AboutPage,
  BlogsPage,
  ContactPage,
  ErrorPage,
  ExperiencePage,
  HomePage,
  ProjectPage,
} from "./pages";

const AppLayout = () => (
  <>
    <Navbar />
    <div className="content-container">
      <Outlet />
    </div>
  </>
);

const App = () => {
  const AppRouter = createBrowserRouter([
    {
      element: <AppLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/experience",
          element: <ExperiencePage />,
        },
        {
          path: "/blogs",
          element: <BlogsPage />,
        },
        {
          path: "/projects",
          element: <ProjectPage />,
        },
        {
          path: "/about",
          element: <AboutPage />,
        },
        {
          path: "/contact",
          element: <ContactPage />,
        },
      ],
    },
  ]);
  return <RouterProvider router={AppRouter}></RouterProvider>;
};

export default App;
