import React from "react";
import { useDocumentTitle } from "../../hooks";

function BlogsPage() {
  useDocumentTitle("thesyscoder | Blogs", false);
  return (
    <div>
      {/* Hero section */}

      {/* Main content */}
      <section className="main_blog_section">
        <h1>Read our blogs</h1>
        <div className="container"></div>
      </section>
    </div>
  );
}

export default BlogsPage;
