// import required modules
import React from "react";
import "./Error.style.scss";
import { useDocumentTitle } from "../../hooks";

// create error page

const ErrorPage = ({ ...propt }) => {
  useDocumentTitle("thesyscoder | Error", false);
  return <div className="main_errorpage_div">This is Error Page</div>;
};
export default React.memo(ErrorPage);
