// Navbar.jsx

import React, { useEffect, useState } from "react";
import "./Navbar.style.scss";
import { NavLink } from "react-router-dom";
import { GitHubLogoIcon, LinkedInLogoIcon } from "@radix-ui/react-icons";

const Navbar = ({ ...props }) => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`navbar ${scrolling ? "scrolled" : ""}`}>
      <div className="brand-link">
        <NavLink to="/" className="brand-navlink">
          <h2>
            the<span className="span">syscoder.</span>
          </h2>
        </NavLink>
      </div>
      <div className="menu-links">
        <ul className="menu-list">
          <li className="menu-item">
            <NavLink exact to="/" className="navlink-menu">
              Home
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/experience" className="navlink-menu">
              Experience
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/blogs" className="navlink-menu">
              Blogs
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/projects" className="navlink-menu">
              Projects
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/about" className="navlink-menu">
              About
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/contact" className="navlink-menu">
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
      {/* social media */}
      <div className="social-media">
        <ul className="social-media-list">
          <li className="auth-links">
            <a
              href="https://linkedin.com/in/pruthvirajs2"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-bg"
            >
              <LinkedInLogoIcon className="icon" />
            </a>
          </li>
          <li className="auth-links">
            <a
              href="https://github.com/thesyscoder"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-bg"
            >
              <GitHubLogoIcon className="icon" />
            </a>
          </li>
        </ul>
      </div>
      {/* Hero section */}
    </div>
  );
};

export default React.memo(Navbar);
